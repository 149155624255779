html {
  height: 100%;
}
body {
  height: 100%;
}
#root {
  height: 100%;
}
.App {
  height: 100%;
}
* {
  box-sizing: border-box;
}
.image {
  opacity: 0;
  transition: opacity 0.4s ease-in-out; /* Плавный переход */
}

.image.loaded {
  opacity: 1; /* Изображение становится видимым */
}
.main {
  justify-content: center;
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  padding: 0;
  overflow-x: hidden;
  background-color: #1a1a1a;
}
.wrappers {
  padding: 0 16px;
  max-width: 700px;
  position: relative;
}
.sphere {
  position: absolute;
  left: 0;
  transform: translateX(-50%);
  width: 80%;
  filter: blur(8px);
  z-index: -1;
}
a {
  user-select: none;
  width: 100%;

  color: none;
  text-decoration: none;
}
.marquee {
  width: 100%;
  padding: 20px 0;
  background: linear-gradient(90deg, #a7f306 0%, #f3cd06 100%);
}
.marquee-container {
  position: relative;
  width: 100%;
  overflow: hidden; /* Это нужно для предотвращения "выпадения" текста */
  z-index: 1; /* Убедитесь, что Marquee находится на верхнем уровне */
}

.marquee-container span {
  white-space: nowrap; /* Запрещает перенос текста */
}
.contentWrapper {
  display: flex;
  flex-direction: column;

  color: #f6f6f6;
}
.wrapper {
  display: flex;
  flex-direction: column;
  font-size: 24px;

  z-index: 3;
  border-radius: 30px;
  width: 100vw;
  padding: 40px 0 0 0;

  align-items: center;
  margin: 5px 0 0 0;
}
.contentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 20px;
  padding: 30px 0;
}
.introWrapper h1 {
  text-align: left;
  color: #f6f6f6;
  margin-bottom: 20px;
}
.introWrapper p {
  color: #f6f6f6;
  margin-bottom: 30px;
}
.introWrapper span {
  color: #a7f306;
}
.servicesWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
}
.serviceItem {
  width: 48%;
  padding: 16px;
  border-radius: 14px;
  background-color: #ffffff12;
  border: 1px solid #ffffff2a;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 5px;
  backdrop-filter:blur(12px);
  
  
}
.serviceItem p {
  margin-bottom: 12px;
  font-weight: 700;
}
.mainButton {
  padding: 15px 20px;
  width: 100%;
  border-radius: 14px;
  border: 1px solid #a7f306;
  color: #a7f306;
  background-color: transparent;
  transition: all 0.2s ease;
}
.mainButton:hover {
  background-color: #a7f306;
  color: #1a1a1a;
}
.primaryButton {
    padding: 15px 20px;
    width: 100%;
    border-radius: 14px;
    border: 1px solid #a7f306;
    color: #1a1a1a;
    background-color: #a7f306;
    transition: all 0.2s ease;
}
.primaryButton:hover {
    border: 1px solid #a7f306;
    background-color: transparent;
    color: #f6f6f6;
}
.technologiesWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.blockName {
  width: 100%;
  text-align: left;
  margin-bottom: 12px;
}
.blockName span {
    color: #a7f306;
}
.blockNameYellow {
  width: 100%;
  text-align: left;
  margin-bottom: 12px;
  padding: 12px 10px;
  color: #1a1a1a;
  background:linear-gradient(90deg, #a7f306 0%, #f3cd06 100%);
}
.techItem {
  padding: 10px 5px;
  border-radius: 10px;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}
.button {
  position: relative;
  background: #a7f306;
  border-radius: 15px;
  display: flex;
  margin-right: 0;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 60px;
  border: #eef1fc 2px solid;

  transition: all 0.2s linear;
}
.button:hover {
  background: #eef1fc;
  border: #a7f306 2px solid;
  border-style: dashed;
  transform: scale(1.05);
}
.button:hover p {
  color: #a7f306;
}
.button p {
  font-size: 20px;

  color: white;
  align-items: center;
  margin: 0;
}
.dashbord > a {
  opacity: 0;
  transform: translateY(-60px);
}
.dashbord > a:nth-child(1) {
  animation: fade 0.2s ease-in-out forwards;
}
.dashbord > a:nth-child(2) {
  animation: fade 0.2s forwards 0.1s;
}
.dashbord > a:nth-child(3) {
  animation: fade 0.2s forwards 0.2s;
}
.dashbord > a:nth-child(4) {
  animation: fade 0.2s forwards 0.3s;
}
.ico {
  right: 10%;
  position: absolute;
  width: 30px;
}
.dashbord {
  height: 350px;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 400px;
}
.ava {
  animation: smokeLivitation 10s infinite;
  margin-top: -25px;
  margin-bottom: 25px;
  width: 150px;
}
.portNavigation {
  justify-content: space-around;
  width: 200px;
  display: flex;
  margin-bottom: 40px;
}
.activeAbNL {
  color: #a7f306 !important;
}
.activeAbNL::before {
  align-items: center;
  border-radius: 50%;
  content: "";
  display: block;
  position: absolute;
  margin-left: 10px;
  bottom: -15px;
  left: 50%;
  width: 5px;
  height: 5px;
  background-color: #a7f306;
  transform: scale(1) !important;
}

.activeAbNL::after {
  border-radius: 50%;
  content: "";
  display: block;
  position: absolute;
  bottom: -17px;
  left: 40%;
  width: 10px;
  height: 10px;
  background-color: #a7f306;
  transform: scale(1) !important;
}
.aboutPortfolioNavLink {
  cursor: pointer;
  margin-top: 0;
  margin-left: 0;
  margin-right: 25px;
  position: relative;
  text-decoration: none;
  color: #2c2c2c;
  font-size: 18px;

  margin-bottom: 0;
}
.aboutPortfolioNavLink:hover {
  color: #a7f306;
}
.aboutPortfolioNavLink::before {
  align-items: center;
  border-radius: 50%;
  content: "";
  display: block;
  position: absolute;
  margin-left: 10px;
  bottom: -15px;
  left: 50%;
  width: 5px;
  height: 5px;
  background-color: #a7f306;
  transform: scale(0);
  transition: transform 0.4s ease-in;
}
.aboutPortfolioNavLink:hover::before {
  transform: scale(1);
}
.aboutPortfolioNavLink::after {
  border-radius: 50%;
  content: "";
  display: block;
  position: absolute;
  bottom: -17px;
  left: 40%;
  width: 10px;
  height: 10px;
  background-color: #a7f306;
  transform: scale(0);
  transition: transform 0.2s ease-in;
}

.aboutPortfolioNavLink:hover::after {
  transform: scale(1);
}
.statisticItem {
  border: 1.08px solid;

  border-image-source: linear-gradient(
    180deg,
    #ffffff 0%,
    rgba(255, 255, 255, 0) 100%
  );
}
.modalWrapper {
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(8px);
  width: 100%;
  height: 100%;
  padding: 16px;
}
.modalBody {
  overflow: hidden;
  width: 100%;
  max-width: 420px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  background-color: #1a1a1a;
  border-radius: 14px;
  color: #f6f6f6;
}
.modalHeader {
  padding: 15px 10px;
  color: #1a1a1a;
  width: 100%;
  background: linear-gradient(90deg, #a7f306 0%, #f3cd06 100%);
}
.form {
    width: 100%;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.inputWrapper {
    width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: flex-start;
}
.react-select-container {
  width: 100%;
}
.formInput {
  width: 100%; /* Сделайте ширину инпута адаптивной */
  padding: 10px 20px; /* Внутренний отступ */
 
  border-radius: 6px; /* Скругление границ */
  border: none;
  font-size: 16px; /* Размер шрифта */
  font-family: "Arial", sans-serif; /* Шрифт */
  outline: none; /* Убирает стандартное выделение */
  transition: all 0.3s ease-in-out; /* Анимация при наведении */
}

.formInput::placeholder {
  
  opacity: 0.7; /* Немного прозрачный */
}

.formInput:focus {
  background-color: rgba(167, 243, 6, 0.1); /* Лёгкий фон при фокусе */
  -webkit-box-shadow: 10px 10px 33px -19px rgba(0,0,0,0.75);
-moz-box-shadow: 10px 10px 33px -19px rgba(0,0,0,0.75);
box-shadow: 10px 10px 33px -19px rgba(0,0,0,0.75);
}
.formTextarea {
    width: 100%; /* Сделайте ширину инпута адаптивной */
    padding: 10px 20px; /* Внутренний отступ */
   
    border-radius: 6px; /* Скругление границ */
    border: none;
    font-size: 16px; /* Размер шрифта */
    font-family: "Arial", sans-serif; /* Шрифт */
    outline: none; /* Убирает стандартное выделение */
    transition: all 0.3s ease-in-out; /* Анимация при наведении */
     min-height: 150px;
}

.formTextarea::placeholder {
  
    opacity: 0.7; /* Немного прозрачный */
}

.formTextarea:focus {
    background-color: rgba(167, 243, 6, 0.1); /* Лёгкий фон при фокусе */
    -webkit-box-shadow: 10px 10px 33px -19px rgba(0,0,0,0.75);
  -moz-box-shadow: 10px 10px 33px -19px rgba(0,0,0,0.75);
  box-shadow: 10px 10px 33px -19px rgba(0,0,0,0.75);
}
.modalActions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
}
.modalActions button {
    width: fit-content;
}
.casesWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
.caseItem {
    width: 100%;
    padding: 16px;
    border-radius: 14px;
    background-color: #ffffff12;
    border: 1px solid #ffffff2a;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 5px;
    text-align: left;
}
.caseItem ul {
    padding-left: 20px;
}
.caseItem img {
    width: 100%;
}
.caseItem h3 {
    font-size: 18px;
}
.caseItem p {
    font-size: 14px;
    font-style: italic;

}
.projectsWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.projectItem {
    width: 100%;
    padding: 16px;
    border-radius: 14px;
    background-color: #ffffff12;
    border: 1px solid #ffffff2a;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 5px;
    text-align: left;
}
.projectInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
}
.projectInfo a {
    width: fit-content;
}
.projectItem img {
    width: 100%;
}

.sphere2 {
    position: absolute;
    right: 0;
    transform: translateX(50%);
    width: 80%;
    bottom: 10%;
    filter: blur(10px);
    z-index: -1;
}
@media (max-device-width: 800px) {
}
@keyframes smokeLivitation {
  0% {
    transform: translateY(0) rotate(0) translateX(0);
  }
  25% {
    transform: translateY(-9px) rotate(2deg) translateX(-4px);
  }
  50% {
    transform: translateY(2px) rotate(-2deg) translateX(2px);
  }
  75% {
    transform: translateY(-9px) translateX(-4px);
  }
  100% {
    transform: translateY(0) translateX(0);
  }
}
@keyframes fade {
  0% {
    transform: translateY(-60px);
    opacity: 0;
  }
  50% {
    opacity: 0.3;
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
