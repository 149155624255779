@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;}
::-webkit-scrollbar {
  width: 9px;
  
}

::-webkit-scrollbar-track {
  
  background-color: #eef1fc;
}

::-webkit-scrollbar-thumb { 
  background-color: #2C2C2C ;
  border: 3px solid #eef1fc ;
  border-radius: 1px;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
